/** @jsx jsx */
import { jsx } from 'theme-ui';
import { H1 } from './Text';

const Hero: React.FC = ({ children }) => (
  <H1
    sx={{
      width: ['100%', '70%'],
    }}
  >
    {children}
  </H1>
);

export { Hero };
