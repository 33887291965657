/** @jsx jsx */
import { jsx } from 'theme-ui';

import { Box, Link as ExternalLink } from 'rebass';
import { useState } from 'react';
import React from 'react';
import { Cross } from './Cross';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from '../i18n/TranslationContext';
import { P } from './Text';
import { LocalizedLink } from './LocalizedLink';

const calendarGridTemplateAreasDesktop: string = `"d1 d2 d3 d4 d5 d6"
                                                  "es es w  c  c  c"
                                                  ".  ll .  .  .  dc"
                                                  ".  .  .  .  .  da"`;

const calendarGridTemplateAreasTablet: string = `"d1  es  .  ."
                                                 "d2  es ll  ."
                                                 "d3  w   .  ."
                                                 "d4  c   .  ."
                                                 "d5  c   .  ."
                                                 "d6  c  dc  da"`;

const calendarGridTemplateAreasMobile: string = `"d1  es  ."
                                                 "d2  es ll"
                                                 "d3  w  ."
                                                 "d4  c  ."
                                                 "d5  c  ."
                                                 "d6  c  ."
                                                 "d6  c  dc"
                                                 "d6  c  da"`;

const calenderGridDate = {
  p: [1, 3],
  fontFamily: 'body',
  color: 'background',
  bg: 'text',
};

interface CalendarLabelProps {
  children: any;
  className: string;
}

const CalendarLabel: React.FC<CalendarLabelProps> = ({
  children,
  className,
}) => {
  return (
    <Box
      sx={{
        variant: 'text.body',
        textAlign: 'center',
        backgroundColor: 'text',
        color: 'background',
      }}
      className={className}
      aria-hidden="true"
    >
      <span
        sx={{
          lineHeight: ['14px', '14px', '32px'],
          letterSpacing: '0.1em',
          width: '100%',
          p: 1,
          py: [2, 2, 0],
        }}
      >
        {children}
      </span>
    </Box>
  );
};

const calendarGridButtonFocus = {
  '&:focus': {
    outline: 'none',
    boxShadow: theme =>
      `0 0 0 2px ${theme.colors.text}, 0 0 0 4px ${theme.colors.background}`,
    borderRadius: '2px',
  },
};

interface CalendarGridAccordionProps {
  title: string;
  desc: string;
  linkText?: string;
  url?: string;
  ariaLabel?: string;
}
const CalendarGridAccordion: React.FC<CalendarGridAccordionProps> = ({
  title,
  desc,
  linkText,
  url,
  ariaLabel,
}) => {
  const [isActive, setisActive] = useState(false);
  const t = useTranslation();
  return (
    <React.Fragment>
      <button
        onClick={e => {
          setisActive(!isActive);
        }}
        sx={{
          variant: 'text.body',
          p: 0,
          mb: 2,
          textDecoration: 'underline',
          cursor: 'pointer',
          textAlign: 'left',
          background: 'transparent',
          fontFamily: 'body',
          fontWeight: 700,
          color: 'background',
          border: 'none',
          ...calendarGridButtonFocus,
        }}
        aria-expanded={isActive}
        aria-label={ariaLabel}
      >
        {title}
        <Cross
          sx={{
            transform: isActive ? 'rotateZ(45deg)' : 'rotateZ(0deg)',
            fill: 'background',
            ml: 2,
            width: '12px',
            height: '12px',
          }}
        />
      </button>
      <Box sx={{ display: isActive ? 'block' : 'none' }}>
        <ReactMarkdown
          source={desc}
          linkTarget="_blank"
          sx={{
            lineHeight: '22px',
            variant: 'text.body',
            color: 'background',
            whiteSpace: 'pre-line',
            a: { variant: 'variants.link', color: 'background' },
            my: 2,
          }}
        />
        {url && url.startsWith('http') && (
          <ExternalLink
            sx={{
              variant: 'variants.link',
              color: 'background',
            }}
            href={url}
            target="_blank"
          >
            {`${linkText} ↗`}
          </ExternalLink>
        )}
        {url && !url.startsWith('http') && (
          <LocalizedLink
            sx={{
              variant: 'variants.link',
              color: 'background',
            }}
            to={url}
          >
            {linkText}
          </LocalizedLink>
        )}
      </Box>
    </React.Fragment>
  );
};

const CalendarGrid: React.FC = props => {
  const t = useTranslation();
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'grid',
          gridGap: '10px',
          width: '100%',
          gridTemplateColumns: [
            '0.5fr 6fr 6fr',
            '0.7fr 6fr 6fr 6fr',
            'repeat(6, 1fr)',
          ],
          gridTemplateRows: [
            'repeat(5, max-content) repeat(3, max-content)',
            'repeat(6, max-content)',
            '1fr max-content max-content max-content',
          ],
          gridTemplateAreas: [
            calendarGridTemplateAreasMobile,
            calendarGridTemplateAreasTablet,
            calendarGridTemplateAreasDesktop,
          ],
          '.rotate-me': {
            zIndex: -1,
            marginLeft: ['auto', 'initial'],
            width: ['22px', '100%'],
            verticalAlign: 'center',
            span: {
              transform: ['rotate(180deg)', 'rotate(180deg)', 'initial'],
              writingMode: ['vertical-lr', 'vertical-lr', 'initial'],
              paddingLeft: ['4px', '7px', 'initial'],
            },
          },
        }}
      >
        <CalendarLabel sx={{ gridArea: 'd1' }} className="rotate-me">
          31 Jan
        </CalendarLabel>
        <CalendarLabel sx={{ gridArea: 'd2' }} className="rotate-me">
          01 Feb
        </CalendarLabel>
        <CalendarLabel sx={{ gridArea: 'd3' }} className="rotate-me">
          02 Feb
        </CalendarLabel>
        <CalendarLabel sx={{ gridArea: 'd4' }} className="rotate-me">
          03 Feb
        </CalendarLabel>
        <CalendarLabel sx={{ gridArea: 'd5' }} className="rotate-me">
          04 Feb
        </CalendarLabel>
        <CalendarLabel sx={{ gridArea: 'd6' }} className="rotate-me">
          05 Feb
        </CalendarLabel>
        <Box sx={{ gridArea: 'es', ...calenderGridDate }}>
          <CalendarGridAccordion
            ariaLabel={`31 January and 1 February 2021 ${t(
              'calgrid_ides_title',
            )}`}
            title={t('calgrid_ides_title')}
            desc={t('calgrid_ides_desc')}
            linkText={t('calgrid_edu_summit_more')}
            url="https://www.edusummit.ixda.org/"
          />
        </Box>
        <Box sx={{ gridArea: 'w', ...calenderGridDate }}>
          <CalendarGridAccordion
            ariaLabel={`2 February 2021 ${t('calgrid_workshop_title')}`}
            title={t('calgrid_workshop_title')}
            desc={t('calgrid_workshop_desc')}
            linkText={t('calgrid_workshop_schedule')}
            url="/program/day_0"
          />
        </Box>
        <Box sx={{ gridArea: 'c', ...calenderGridDate }}>
          <CalendarGridAccordion
            ariaLabel={`3 to 5 February 2021 ${t('calgrid_ixd21_title')}`}
            title={t('calgrid_ixd21_title')}
            desc={t('calgrid_ixd21_desc')}
            linkText={t('calgrid_conf_schedule')}
            url="/program"
          />
        </Box>
        <Box sx={{ gridArea: 'll', ...calenderGridDate }}>
          <CalendarGridAccordion
            ariaLabel={`1 February 2021 ${t('calgrid_ixd21_title')}`}
            title={t('calgrid_ll_title')}
            desc={t('calgrid_ll_desc')}
            linkText={t('calgrid_llr_more')}
            url="/program/local-leaders-retreat"
          />
        </Box>
        <Box sx={{ gridArea: 'dc', ...calenderGridDate }}>
          <CalendarGridAccordion
            ariaLabel={`2 to 5 February 2021 ${t('calgrid_sdc_title')}`}
            title={t('calgrid_sdc_title')}
            desc={t('calgrid_sdc_desc')}
            linkText={t('calgrid_sdc_more')}
            url="http://sdc.ixda.org/"
          />
        </Box>
        <Box sx={{ gridArea: 'da', ...calenderGridDate }}>
          <CalendarGridAccordion
            ariaLabel={`February 2021 ${t('calgrid_ixd21_title')}`}
            title={t('calgrid_awards_title')}
            desc={t('calgrid_awards_desc')}
            linkText={t('calgrid_ixdaw_more')}
            url="https://awards.ixda.org/"
          />
        </Box>
      </Box>
      <P sx={{ textAlign: 'right' }}>{t('calgrid_advisory')}</P>
    </React.Fragment>
  );
};

export { CalendarGrid };
