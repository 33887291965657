/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex, Text, Button, Link as ExternalLink } from 'rebass';

import MainLayout from '../layouts/MainLayout';
import { Hero } from '../components/Hero';
import { Link } from 'gatsby';
import { H3, H4, P } from '../components/Text';
import { Container, ContainerFlex } from '../components/Container';
import { CalendarGrid } from '../components/CalendarGrid';

const IndexPage = ({ ...rest }) => {
  return (
    <MainLayout>
      <div id="content" />
      <Container mb={[3, 4, 5]}>
        <Hero>
          Design in
          <br />
          perilous times
        </Hero>
      </Container>

      <ContainerFlex mb={[4, 4, 5]} flexWrap="wrap" alignItems="flex-end">
        <Box width={[1, 1 / 2]} mb={[4, 0]} pr={[0, 3, 3]}>
          <Text sx={{ fontSize: 3, fontFamily: 'body' }}>
            Online
            <br />
            <strong sx={{ fontWeight: 700 }}>31 Jan - 5 Feb 2021</strong>
          </Text>
        </Box>
      </ContainerFlex>

      <ContainerFlex flexDirection={'column'}>
        <Flex mb={5} flexWrap="wrap">
          <Box width={[1, 1 / 2]} pr={[0, 3, 3]}>
            <P>
              For the first time in its history, Interaction Week happened
              online in 2021. This year was global, around-the-clock and more
              accessible than ever. We had you ready to solve the problems of
              our generation. This was one for the books!
            </P>
          </Box>
          <Box width={[1, 1 / 2]} pl={[0, 3, 3]}>
            <P>
              Thank you for joining Interaction Week 2021! We engaged in
              conversations about the perils in our world today, what led us to
              this place and inspiring solutions to dramatically change our path
              forward. Our amazing speakers will delve into the themes of{' '}
              <strong sx={{ fontWeight: 700 }}>anger</strong>,{' '}
              <strong sx={{ fontWeight: 700 }}>accountability</strong>, and{' '}
              <strong sx={{ fontWeight: 700 }}>action</strong>.
            </P>
          </Box>
        </Flex>
        <Box sx={{ mb: 5 }}>
          <CalendarGrid />
        </Box>
        <Box sx={{ mb: 5 }}>
          <H3>A whole new experience</H3>
          <P>
            Setting our theme pre-pandemic involved unforeseen foreshadowing.
            We've crafted Interaction21 as an online-first event to be a unique
            and epic virtual experience. Expect the same level of high quality
            curated professional content delivered to you in the most engaging
            ways.{' '}
            <Link to="/about/faq" sx={{ variant: 'variants.link' }}>
              Learn more by perusing our FAQs.
            </Link>
          </P>
          <Flex sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <Box sx={{ flex: ['1 0 100%', '1 0 30%'], px: 2 }}>
              <H4>Connecting with people</H4>
              <P>
                With expert roundtable discussions, live Q&amp;A sessions,
                interactive workshops, group evening activities and social
                events, we've carefully considered how to best provide
                opportunities for connections to be made.
              </P>
            </Box>
            <Box sx={{ flex: ['1 0 100%', '1 0 30%'], px: 2 }}>
              <H4>Helping you focus</H4>
              <P>
                Our 8-hour single stream of content has shorter talks and more
                cognitive breaks balancing live and recorded material helping
                you immerse yourself into the conference without the pressures
                of FOMO.
              </P>
            </Box>
            <Box sx={{ flex: ['1 0 100%', '1 0 30%'], px: 2 }}>
              <H4>Program localization</H4>
              <P>
                Our curated program will stream for 3 different regions: the
                Americas, Europe-Middle-East-Africa, &amp; Asia-Pacific. No
                matter where you are in the world, you can conveniently tune
                into IxD21 from your region.
              </P>
            </Box>
            <Box sx={{ flex: ['1 0 100%', '1 0 30%'], px: 2 }}>
              <H4>Quality content</H4>
              <P>
                The programming embodies our theme with talks and sessions from
                global leaders in our industry and companies around the world.
                We have a full production and programming team to ensure a clear
                broadcast and highly professional, relevant content.
              </P>
            </Box>
            <Box sx={{ flex: ['1 0 100%', '1 0 30%'], px: 2 }}>
              <H4>Entertainment &amp; swag</H4>
              <P>
                In collaboration with our partners, we’ll make sure you can
                conveniently learn about them, as well as get your hands on
                physical swag and digital goodies. Also, keep an eye on frequent
                breaks throughout the day where we’ll be hosting a variety of
                entertaining content.
              </P>
            </Box>
            <Box sx={{ flex: ['1 0 100%', '1 0 30%'], px: 2 }}>
              <H4>Advancing our profession</H4>
              <P>
                Without your support, we wouldn't have a platform to showcase
                amazing talent and thought leaders. Interaction Week provides an
                unprecedented opportunity for you to take an active role in our
                global community.
              </P>
            </Box>
          </Flex>
        </Box>
      </ContainerFlex>
    </MainLayout>
  );
};

export default IndexPage;
